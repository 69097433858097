.block {
  opacity: 0.8;
  cursor: pointer;
}

.block:hover {
  opacity: 1;
}

.treemap-tooltip {
  min-width: 140px;
  position: absolute;
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  opacity: 0.9;
  transform: translate(-50%, -100%);
  z-index: 999;
}
